/* .container-lg {
    max-width: 1600px;
  } */
/* --- Website Fonts --- */
@font-face {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  src: url("../fonts/Roboto/Roboto-Thin.ttf");
}
@font-face {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  src: url("../fonts/Roboto/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
  font-style: normal;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../fonts/Roboto/Roboto-Medium.ttf");
  font-style: normal;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
  font-style: normal;
}
html {
  scroll-behavior: initial !important;
}

* {
  text-decoration: none;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--black);
}
a:hover {
  text-decoration: none;
  color: var(--black);
}

.common-word-wrap {
  word-wrap: break-word;
}

img {
  width: 100%;
}

ul {
  list-style: none;
}

svg {
  width: 30px;
  height: 30px;
}

.p-70 {
  padding: 70px 0;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--celeste);
  background-color: var(--light-s-gray);
}

.overflowhidden {
  overflow: hidden;
}

body.overflowhidden {
  overflow: hidden;
}

/*--common box--*/
.common-box {
  background-color: var(--white);
  border-radius: 4px;
  padding: 15px;
  position: relative;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

/*--common input--*/
.common-input input {
  padding: 8px 12px 8px 12px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.4196078431);
  height: 56px;
}
.common-input select {
  padding: 8px 12px 8px 12px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.4196078431);
  height: 56px;
}
.common-input .form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: none;
}

/*--common button css start--*/
.common-btn-group {
  display: flex;
  gap: 15px;
}

.common-btn-item {
  align-items: center;
  border-radius: 4px;
  color: var(--black-800);
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 44px;
  justify-content: center;
  min-width: 100px;
  padding: 0 20px;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  letter-spacing: 1.25px;
}

button.common-btn-item.cbi-solid {
  background: var(--primary-color);
  color: var(--white);
}
button.common-btn-item.cbi-solid:hover {
  background: var(--primary-hover-color);
  color: var(--white);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

button.common-btn-item.cbi-light {
  background: var(--secondary-color);
  color: var(--black-800);
}
button.common-btn-item.cbi-light:hover {
  background: var(--secondary-hover-color);
  color: var(--black-800);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/*--common button css end--*/
/*common heading css start--*/
.common-heading {
  font-size: 48px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
}

.common-subheading {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--black-500);
}

.common-heading.ch-medium {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: var(--black-800);
}

.common-heading.ch-small {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: var(--black-800);
}

.common-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--black-500);
}

/*--introducing-custmized-ai start--*/
.introducing-custmized-ai {
  padding: 140px 0 100px;
  position: relative;
}
.introducing-custmized-ai .ica-bg {
  position: absolute;
  right: 0;
  left: auto;
  height: 940px;
  width: -moz-fit-content;
  width: fit-content;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -9;
}
.introducing-custmized-ai .row {
  align-items: center;
}
.introducing-custmized-ai .ica-left {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 576px;
}
.introducing-custmized-ai .ica-right img {
  width: 100%;
}

/*--introducing-custmized-ai End--*/
/*--trusted-Partner Start--*/
.trusted-Partner .tp-slider img {
  filter: grayscale(1);
  width: 150px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.trusted-Partner .tp-slider img:hover {
  filter: none;
}

/*--trusted-Partner End--*/
/*--idea-into-startup start--*/
.idea-into-startup {
  padding: 150px 0 70px;
}
.idea-into-startup .its-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.idea-into-startup .its-right .common-box {
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 12px;
  padding: 30px;
}
.idea-into-startup .its-right .common-box img {
  width: 167px;
  height: 167px;
  overflow: hidden;
  margin: 0 auto;
}
.idea-into-startup .its-right .common-box .common-heading.ch-small {
  text-align: center;
}
.idea-into-startup .its-right .common-box:hover {
  box-shadow: 0 5px 9px 0 rgba(115, 84, 239, 0.4);
}

/*--idea-into-startup End--*/
/*--Lets Talk Tech start--*/
.lets-talk-tech {
  position: relative;
}
.lets-talk-tech .body-gradiant {
  position: absolute;
  height: 1965px;
  top: 0;
  z-index: -9;
  left: 0;
  right: 0;
}
.lets-talk-tech .ltt-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.lets-talk-tech .common-subheading {
  max-width: 790px;
  margin: 0 auto;
}

/*--Lets Talk Tech End--*/
/*--rated-review start--*/
.rated-reviews {
  padding: 70px 0;
  overflow: hidden;
}
.rated-reviews .rr-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}
.rated-reviews .rr-slider .slick-list {
  overflow: visible;
}
.rated-reviews .rr-slider .common-box {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 20px;
}
.rated-reviews .rr-slider .common-box .star-reacting ul {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
}
.rated-reviews .rr-slider .common-box .star-reacting ul li svg {
  width: 20px;
  height: 20px;
  color: #FFCA28;
}
.rated-reviews .rr-slider .common-box .review-detail {
  display: flex;
  align-items: center;
  gap: 15px;
}
.rated-reviews .rr-slider .common-box .rd-inne-left {
  width: 64px;
  height: 64px;
  background: var(--primary-color);
  position: relative;
  border-radius: 50px;
}
.rated-reviews .rr-slider .common-box .rd-inne-left svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  color: var(--white);
}
.rated-reviews .rr-slider .common-box .rd-inne-right .common-heading.ch-small {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.150000006px;
  text-align: left;
}

/*--rated-review End--*/
/*--contact from start--*/
.contactus-form {
  position: relative;
}
.contactus-form .footer-gradiant {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 765px;
  z-index: -9;
}
.contactus-form .cf-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: var(--white);
  padding: 25px;
}
.contactus-form .cf-left .cf-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.contactus-form .cf-right img {
  width: 100%;
  max-width: 445px;
}
.contactus-form .common-btn-item.cbi-solid {
  padding: 0 20px;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

/*--contact from End--*//*# sourceMappingURL=custome.css.map */