$color_1: var(--black);
$color_2: var(--celeste);
$color_3: var(--black-800);
$color_4: var(--white);
$color_5: var(--black-500);
$color_6: #FFCA28;
$font-family_1: "Roboto", sans-serif;
$background-color_1: var(--light-s-gray);
$background-color_2: var(--white);

/* .container-lg {
    max-width: 1600px;
  } */
/* --- Website Fonts --- */

@font-face {
	font-family: "Roboto", sans-serif;
	font-style: normal;
	src: url("../fonts/Roboto/Roboto-Thin.ttf");
}
@font-face {
	font-family: "Roboto", sans-serif;
	font-style: normal;
	src: url("../fonts/Roboto/Roboto-Light.ttf");
}
@font-face {
	font-family: "Roboto", sans-serif;
	src: url("../fonts/Roboto/Roboto-Regular.ttf");
	font-style: normal;
}
@font-face {
	font-family: "Roboto", sans-serif;
	src: url("../fonts/Roboto/Roboto-Medium.ttf");
	font-style: normal;
}
@font-face {
	font-family: "Roboto", sans-serif;
	src: url("../fonts/Roboto/Roboto-Bold.ttf");
	font-style: normal;
}

html {
	scroll-behavior: initial !important;
}
* {
	text-decoration: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	color: $color_1;
	&:hover {
		text-decoration: none;
		color: $color_1;
	}
}
.common-word-wrap {
	word-wrap: break-word;
}
img {
	width: 100%;
}
ul {
	list-style: none;
}
svg {
	width: 30px;
	height: 30px;
}
.p-70 {
	padding: 70px 0;
}
body {
	padding: 0;
	margin: 0;
	font-family: $font-family_1;
	font-weight: 400;
	font-size: 16px;
	color: $color_2;
	background-color: $background-color_1;
}
.overflowhidden {
	overflow: hidden;
}
body.overflowhidden {
	overflow: hidden;
}

/*--common box--*/

.common-box {
	background-color: $background-color_2;
	border-radius: 4px;
	padding: 15px;
	position: relative;
	box-shadow: 0px 2px 4px 0px #00000033;
}

/*--common input--*/
.common-input {
	input {
		padding: 8px 12px 8px 12px;
		gap: 10px;
		border-radius: 4px;
		border: 1px solid #0000006B;
		height: 56px;
	}
	select {
		padding: 8px 12px 8px 12px;
		gap: 10px;
		border-radius: 4px;
		border: 1px solid #0000006B;
		height: 56px;
	}
	.form-control:focus {
		color: var(--bs-body-color);
		background-color: var(--bs-body-bg);
		border-color: var(--primary-color);
		outline: 0;
		box-shadow: none
	}
}
/*--common button css start--*/
.common-btn-group {
	display: flex;
	gap: 15px;
}
.common-btn-item {
	align-items: center;
	border-radius: 4px;
	color: $color_3;
	border: none;
	cursor: pointer;
	display: flex;
	font-size: 14px;
	font-weight: 600;
	height: 44px;
	justify-content: center;
	min-width: 100px;
	padding: 0 20px;
	position: relative;
	transition: all .3s ease-in-out;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	text-transform: uppercase;
	letter-spacing: 1.25px;
}
button.common-btn-item.cbi-solid {
	background: var(--primary-color);
	color: $color_4;
	&:hover {
		background: var(--primary-hover-color);
		color: $color_4;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}
}
button.common-btn-item.cbi-light {
	background: var(--secondary-color);
	color: $color_3;
	&:hover {
		background: var(--secondary-hover-color);
		color: $color_3;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}
}
/*--common button css end--*/

/*common heading css start--*/
.common-heading {
	font-size: 48px;
	font-weight: 600;
	line-height: 50px;
	text-align: left;
}
.common-subheading {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.5px;
	text-align: left;
	color: $color_5;
}
.common-heading.ch-medium {
	font-size: 34px;
	font-weight: 400;
	line-height: 40px;
	letter-spacing: 0.25px;
	color: $color_3;
}
.common-heading.ch-small {
	font-size: 20px;
	font-weight: 600;
	line-height: 32px;
	letter-spacing: 0.25px;
	color: $color_3;
}
.common-text {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: $color_5;
}

/*--introducing-custmized-ai start--*/
.introducing-custmized-ai {
	padding: 140px 0 100px;
	position: relative;
	.ica-bg {
		position: absolute;
		right: 0;
		left: auto;
		height: 940px;
		width: fit-content;
		top: 0;
		object-fit: cover;
		z-index: -9;
	}
	.row {
		align-items: center;
	}
	.ica-left {
		display: flex;
		flex-direction: column;
		gap: 30px;
		max-width: 576px;
	}
	.ica-right {
		img {
			width: 100%;
		}
	}
}

/*--introducing-custmized-ai End--*/

/*--trusted-Partner Start--*/

.trusted-Partner {
	.tp-slider {
		img {
			filter: grayscale(1);
			width: 150px;
			transition: all .3s ease-in-out;
			cursor: pointer;
			&:hover {
				filter: none;
			}
		}
	}
}

/*--trusted-Partner End--*/

/*--idea-into-startup start--*/
.idea-into-startup {
	padding: 150px 0 70px;
	.its-left {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	.its-right {
		.common-box {
			img {
				width: 167px;
				height: 167px;
				overflow: hidden;
				margin: 0 auto;
			}
			text-align: center;
			transition: all .3s ease-in-out;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			gap: 15px;
			margin-bottom: 12px;
			padding: 30px;
			.common-heading.ch-small {
				text-align: center;
			}
			&:hover {
				box-shadow: 0 5px 9px 0 #7354ef66;
			}
		}
	}
}

/*--idea-into-startup End--*/

/*--Lets Talk Tech start--*/
.lets-talk-tech {
	position: relative;
	.body-gradiant {
		position: absolute;
		height: 1965px;
		top: 0;
		z-index: -9;
		left: 0;
		right: 0;
	}
	.ltt-inner {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	.common-subheading {
		max-width: 790px;
		margin: 0 auto;
	}
}

/*--Lets Talk Tech End--*/

/*--rated-review start--*/
.rated-reviews {
	padding: 70px 0;
	overflow: hidden;
	.rr-inner {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		gap: 20px;
	}
	.rr-slider {
		.slick-list {
			overflow: visible;
		}
		.common-box {
			box-shadow: 0px 2px 4px 0px #00000033;
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			gap: 15px;
			margin: 0 20px;
			.star-reacting {
				ul {
					display: flex;
					padding-left: 0;
					margin-bottom: 0;
					li {
						svg {
							width: 20px;
							height: 20px;
							color: $color_6;
						}
					}
				}
			}
			.review-detail {
				display: flex;
				align-items: center;
				gap: 15px;
			}
			.rd-inne-left {
				width: 64px;
				height: 64px;
				background: var(--primary-color);
				position: relative;
				border-radius: 50px;
				svg {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 24px;
					height: 24px;
					color: $color_4;
				}
			}
			.rd-inne-right {
				.common-heading.ch-small {
					font-size: 15px;
					font-weight: 400;
					line-height: 28px;
					letter-spacing: 0.15000000596046448px;
					text-align: left;
				}
			}
		}
	}
}

/*--rated-review End--*/

/*--contact from start--*/
.contactus-form {
	position: relative;
	.footer-gradiant {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 765px;
		z-index: -9;
	}
	.cf-left {
		.cf-form {
			display: flex;
			flex-direction: column;
			gap: 15px;
		}
		display: flex;
		flex-direction: column;
		gap: 20px;
		background: var(--white);
		padding: 25px;
	}
	.cf-right {
		img {
			width: 100%;
			max-width: 445px;
		}
	}
	.common-btn-item.cbi-solid {
		padding: 0 20px;
		min-width: fit-content;
	}
}
/*--contact from End--*/