/* Header styles */
.main-header {
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transition: all .3s ease-in-out;
    z-index: 9;
}

.main-header.scroll {
    background: var(--white);
    box-shadow: 0 -12px 24px 0 #7354ef;
    z-index: 999;
    transition: all .3s ease-in-out;
}

.main-header .mh-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-header .mh-logo a {
    color: var(--black-800);
    font-size: 1.5rem;
    text-decoration: none;
}

.main-header .mh-nav {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.main-header .mh-menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.main-header .mh-menu li {
    margin: 0 15px;
}

.main-header .mh-menu li a {
    text-decoration: none;
    color: var(--black-800);
    transition: color 0.3s ease;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}

.main-header .mh-menu li a:hover {
    color: var(--primary-color);
}

.main-header .mh-buttons {
    display: flex;
    gap: 15px;
}

.overlay {
    position: fixed;
    top: 0;
    left: auto;
    width: 100%;
    height: 100%;
    z-index: 5;
    transition: all .3s ease-in-out;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: var(--black-500);
  }
  


/* Mobile Menu Icon */
.mobile-menu-icon {
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
}

.mobile-menu-icon .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 3px 0;
    transition: all 0.3s ease;
}

/* Responsive Design */
@media (max-width: 1070px) {

    .main-header {
        padding: 10px 0;
        background: var(--white);
        position: fixed;
    }

    .main-header .mh-buttons.common-btn-group {
        display: none;
    }

    .main-header  .mh-logo img {
        width: 90px;
    }

    .main-header .mobile-menu-icon .bar {
       background: var(--primary-color);     
    }

    .main-header .mh-menu li a {
        color: var(--white);
    }

    .main-header .mh-menu li a:hover {
        color: var(--primary-color);
    }

    .main-header .mh-nav {
        position: absolute;
        top: 93px;
        left: -250px;
        width: 250px;
        background-color: var(--black);
        height: 100vh;
        overflow: hidden;
        transition: .3s ease-in-out;
        justify-content: start;
    }

    .main-header .mh-nav.active {
        left: 0;
    }

    .main-header .mh-menu {
        flex-direction: column;
        align-items: start;
        padding: 15px;
    }

    .main-header .mh-nav.active .mh-menu {
        width: 100%;
    }

    .main-header .mh-menu li {
        margin: 15px 0;
    }

 
    .mobile-menu-icon {
        display: flex;
    }
}