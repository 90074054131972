:root{
    --white: #ffffff;
    --white-900: rgba(255, 255, 255, 0.9);
    --white-800: rgba(255, 255, 255, 0.8);
    --white-700: rgba(255, 255, 255, 0.7);
    --white-600: rgba(255, 255, 255, 0.6);
    --white-500: rgba(255, 255, 255, 0.5);
    --white-400: rgba(255, 255, 255, 0.4);
    --white-300: rgba(255, 255, 255, 0.3);
    --white-200: rgba(255, 255, 255, 0.2);
    --white-100: rgba(255, 255, 255, 0.1);

    --black: #000000;
    --black-800: rgba(0, 0, 0, 0.87);
    --black-700: rgba(0, 0, 0, 0.7);
    --black-600: rgba(0, 0, 0, 0.6);
    --black-500: rgba(0, 0, 0, 0.58);
    --black-400: rgba(0, 0, 0, 0.4);
    --black-300: rgba(0, 0, 0, 0.3);
    --black-200: rgba(0, 0, 0, 0.2);
    --black-100: rgba(0, 0, 0, 0.1);
    
    --light-s-gray: #f9fafb;

    --primary-color: #7354EF;
    --primary-hover-color :#563eb7;
    --secondary-color: #F5F5F5;
    --secondary-hover-color: #d7d7d7;
    

}