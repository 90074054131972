.footer-setion {
    background: var(--primary-color);
    /* padding: 80px 80px 64px 80px; */
    padding: 30px 30px 30px 30px;
}

.footer-setion .fs-inner .fs-left .common-subheading {
    color: var(--white);
}

.footer-setion .fs-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.footer-setion .fs-right ul {
    display: flex;
    gap: 20px;
    margin-bottom: 0;
    padding-left: 0;
}

.footer-setion .fs-right ul li a {
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

@media only screen and (max-width: 767px) { 

    .footer-setion {
        padding: 30px 30px 20px 30px;
    }

    .footer-setion .fs-inner {
        justify-content: center;
        display: block;
    }

    .footer-setion .fs-right ul {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        
    }

    .footer-setion .fs-right ul li a {
        font-size: 14px;
    }

}