@media (min-width: 768px) and (max-width: 1200px) {

    .container-lg {
        max-width: 1140px;
    }

    .p-70 {
        padding: 35px 0;
    }

    /**common input css start**/
    .common-input input {
        height: 50px;
    }

    /**common input css End**/


    /**common button css start**/
    .common-btn-item {
        font-size: 12px;
        letter-spacing: 0.5px;
    }

    /**common button css end**/

    /**common heading css start**/

    .common-heading {
        font-size: 30px;
        line-height: 36px;
    }

    .common-heading.ch-medium {
        font-size: 24px;
        line-height: 30px;

    }

    .common-subheading {
        font-size: 16px;

    }

    .common-heading.ch-small {
        font-size: 20px;
        line-height: 30px;

    }

    /**common heading css end**/

    /**introducing-custmized-ai css start**/

    .introducing-custmized-ai {
        padding: 130px 0 40px;
    }

    .introducing-custmized-ai .ica-bg {
        width: -moz-fit-content;
        width: 100%;
        top: 0;
        object-fit: cover;
        z-index: -9;
    }

    /**introducing-custmized-ai css End**/

    /**idea-into-startup css Start**/
    .idea-into-startup {
        padding: 35px 0 35px;
    }

    .idea-into-startup .its-left {
        gap: 15px;
    }

    .idea-into-startup .its-right .common-box {
        gap: 10px;
        padding: 15px;
    }

    .idea-into-startup .its-right .px-3 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    /**idea-into-startup css End**/

    /**Trusted partner css Start**/

    .trusted-Partner .common-heading.ch-medium.text-center.pb-5 {
        padding-bottom: 30px !important;
    }

    .trusted-Partner .tp-slider img {
        object-fit: contain;
    }

    /**Trusted partner css End**/

    /**lets-talk-tech css Start**/

    .lets-talk-tech .body-gradiant {
        height: auto;
    }

    /**lets-talk-tech css End**/


    /**rated-reviews css start**/

    .rated-reviews .rr-slider .common-box {
        margin: 0 10px;
    }

    /**rated-reviews css End**/

    /**contactus-form css Start**/

    .contactus-form .footer-gradiant {
        height: 100%;
    }

    /**contactus-form css End**/

}




@media only screen and (max-width: 767px) {

    .p-70 {
        padding: 35px 0;
    }

    /**common input css start**/
    .common-input input {
        height: 50px;
    }

    /**common input css End**/


    /**common button css start**/
    .common-btn-item {
        font-size: 12px;
        letter-spacing: 0.5px;
    }

    /**common button css end**/

    /**common heading css start**/

    .common-heading {
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .common-heading.ch-medium {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .common-subheading {
        font-size: 14px;
        text-align: center;
    }

    .common-heading.ch-small {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }

    /**common heading css end**/

    /**introducing-custmized-ai css start**/

    .introducing-custmized-ai {
        padding: 130px 0 40px;
    }

    .introducing-custmized-ai .row {
        align-items: center;
        flex-direction: column-reverse;
        gap: 30px;
    }

    .introducing-custmized-ai .ica-left {
        gap: 15px;
        max-width: 100%;
    }

    .introducing-custmized-ai .ica-right img {
        width: 100%;
        margin: 0 auto;
        display: block;
        max-width: 400px;
    }

    .introducing-custmized-ai .ica-left .common-heading,
    .introducing-custmized-ai .ica-left .common-subheading {
        text-align: center;
    }

    .introducing-custmized-ai .common-btn-group {
        justify-content: center;
        flex-wrap: wrap;
    }

    /**introducing-custmized-ai css End**/

    /**Trusted partner css Start**/

    .trusted-Partner .common-heading.ch-medium.text-center.pb-5 {
        padding-bottom: 30px !important;
    }

    .trusted-Partner .tp-slider img {
        object-fit: contain;
    }

    /**Trusted partner css End**/

    /**idea-into-startup css Start**/

    .idea-into-startup {
        padding: 35px 0 35px;
    }

    .idea-into-startup .its-left {
        gap: 15px;
    }

    .idea-into-startup .its-right .common-box {
        margin-top: 10px;
        gap: 10px;
        padding: 15px;
    }

    .idea-into-startup .its-right {
        overflow: hidden;
    }

    /* .idea-into-startup .its-right .px-3 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    } */

    /**idea-into-startup css End**/


    /**lets-talk-tech css Start**/

    .lets-talk-tech .ltt-inner {
        gap: 15px;
    }

    /**lets-talk-tech css End**/

    /**rated-reviews css start**/

    .rated-reviews .rr-inner {
        gap: 15px;
    }

    .rated-reviews .rr-slider .common-box .common-heading.ch-small,
    .rated-reviews .rr-slider .common-box .common-subheading {
        text-align: left;
        line-height: 22px;
    }

    .rated-reviews .rr-slider .common-box .review-detail {
        gap: 10px;
    }

    .rated-reviews .rr-slider .common-box {
        gap: 10px;
        margin: 0 15px;
    }

    .rated-reviews .rr-slider .common-box .rd-inne-left {
        width: 50px;
        height: 50px;
    }

    .rated-reviews .rr-slider .common-box .rd-inne-right .common-heading.ch-small {
        font-size: 14px;
    }

    .rated-reviews .rr-slider .slick-prev:before,
    .rated-reviews .rr-slider .slick-next:before {
        color: var(--primary-color);
        font-size: 30px;
        opacity: 9;
    }

    .rated-reviews .rr-slider .slick-next {
        right: 15px;
    }

    .rated-reviews .rr-slider .slick-prev {
        left: 0;
        z-index: 9;
    }

    /**rated-reviews css End**/

    /**contactus-form css Start**/

    .contactus-form .row {
        flex-direction: column-reverse;
    }

    .contactus-form .cf-left {
        gap: 15px
    }

    .contactus-form .cf-left .cf-form.mt-4 {
        margin-top: 10px !important;
    }

    .contactus-form .cf-right img {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        display: block;
    }

    .contactus-form .footer-gradiant {
        top: auto;
        height: auto;
        bottom: 0;
    }

    /**contactus-form css End**/

}